import React, { useEffect, useState } from 'react'
import axios from '../axios';
import HeaderSecond from '../HeaderSecond'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import NoItemAdded from '../img/other/no-item.png'
import { Calculation } from './Calculation';
import couponDiscount from '../img/other/discount.png'
import { ToastContainer, toast } from 'react-toastify';
import failedImg from '../img/other/failed.gif';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';

const RegisterNow = () => {
    const [ApiCall, setApiCall] = useState(true);
    const [AllTickets, setAllTickets] = useState([]);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [FinalTotalPrice, setFinalTotalPrice] = useState(0);
    const [TotalAttendee, setTotalAttendee] = useState(0);
    const [TotalDiscount, setTotalDiscount] = useState(0);
    const { EventId } = useParams();
    const navigate = useNavigate();
    const [EventData, setEventData] = useState('')
    const location = useLocation();
    const [UtmCampaign, setUtmCampaign] = useState("")

    const [OrgGstPercentage, setOrgGstPercentage] = useState('');
    const [TicketYtcrBasePrice, setTicketYtcrBasePrice] = useState('');
    const [YtcrFeePercentage, setYtcrFeePercentage] = useState('');
    const [PlatformFeePercentage, setPlatformFeePercentage] = useState('');
    const [PaymentGatewayFeePercentage, setPaymentGatewayFeePercentage] = useState('');
    const [PaymentGatewayGstPercentage, setPaymentGatewayGstPercentage] = useState('');
    const [CollectGst, setCollectGst] = useState('');
    const [PriceTaxesStatus, setPriceTaxesStatus] = useState('');

    const [CalTicketPrice, setCalTicketPrice] = useState(false);
    const [ClickOnTicketId, setClickOnTicketId] = useState(0);

    const [TicketIds, setTicketIds] = useState("");
    const [Coupons, setCoupons] = useState([]);
    const [CouponApiCall, setCouponApiCall] = useState(false);
    const [appliedCoupons, setAppliedCoupons] = useState([]);
    const [Calculate, setCalculate] = useState(false);
    const [CounterCalculate, setCounterCalculate] = useState(false);
    const [CartTicketIds] = useState([])

    const [LoaderFlag, setLoaderFlag] = useState(false);
    const [CouponCode, setCouponCode] = useState("");

    const [PaymentFailurePopup, setPaymentFailurePopup] = useState(false);
    const [RaceCategoryCharges, setRaceCategoryCharges] = useState([]);
    const [NewCouponData, setNewCouponData] = useState([]);

    const [EventRegiStatus, setEventRegiStatus] = useState(2); // 1 - Single / 2 - Multiple Registreation

    const [TotalBookingRegistration, setTotalBookingRegistration] = useState(0);
    const [OverallLimit, setOverallLimit] = useState(0);

    let [TempCount, setTempCount] = useState(0);
    const [BookingLimitExceed, setBookingLimitExceed] = useState(0);
    
    const BookTicketEventId = localStorage.getItem('BookTicketEventId');
    const { payment_status } = useParams();
  
    localStorage.removeItem("booking_pay_id");
    const [Login, setLogin] = useState(false);
    
    useEffect(() => {
        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        // console.log(RacesToken);
        if(RacesToken && RacesToken !== ''){
           setLogin(false);
        }else{
           setLogin(true);
        }
    }, []);

    useEffect(() => {
        if (payment_status === 'failure') {
            setPaymentFailurePopup(true);
        }
        else {
            //   navigate('/events')
            setPaymentFailurePopup(false);
        }
    }, [payment_status]);

    // user link hit
    useEffect(() => {
        if (location.state) {
            if (location.state.utm_campaign) {
                // console.log(location.state.utm_campaign);
                setUtmCampaign(location.state.utm_campaign)  // set on location url
            }
        }
    }, [location.state])

    // GET TICKET API
    useEffect(() => {
        if (ApiCall) {
            setLoaderFlag(true);
            axios.post("get_event_ticket", {
                "event_id": EventId ? EventId : BookTicketEventId
            })
                .then((response) => {
                    // console.log(response.status);
                    if(response.status === 200 && response.data.data.EventData && response.data.data.EventData.length > 0){
                        setApiCall(false)
                        setAllTickets(response.data.data.event_tickets);
                        setEventData(response.data.data.EventData[0]);
                        setEventRegiStatus(response.data.data.EventData[0].event_registration_status);
                        setRaceCategoryCharges(response.data.data.race_category_charges_details);
                        setTotalBookingRegistration(response.data.data.EventData ? response.data.data.EventData[0].total_booking_registration : 0);
                        setOverallLimit(response.data.data.EventData ? response.data.data.EventData[0].overall_limit : 0);
                        // setOrgGstPercentage(response.data.data.OrgGstPercentage)
                        // setTicketYtcrBasePrice(response.data.data.TicketYtcrBasePrice)
                        // setYtcrFeePercentage(response.data.data.YTCR_FEE_PERCENT)
                        // // setPlatformFeePercentage(response.data.data.PLATFORM_FEE_PERCENT)
                        // setPaymentGatewayFeePercentage(response.data.data.PAYMENT_GATEWAY_FEE_PERCENT)
                        // setPaymentGatewayGstPercentage(response.data.data.PAYMENT_GATEWAY_GST_PERCENT)

                        // Calculation(TicketArr[index], OrgGstPercentage, TicketYtcrBasePrice, YTCR_FEE_PERCENT, PAYMENT_GATEWAY_FEE_PERCENT, PAYMENT_GATEWAY_GST_PERCENT, CollectGst, PriceTaxesStatus);

                        setCollectGst(response.data.data.CollectGst);
                        setPriceTaxesStatus(response.data.data.PriceTaxesStatus);

                        if (response.data.data.event_tickets !== "") {
                            // console.log(response.data.data.event_tickets)
                            // const keysArray = response.data.data.event_tickets.map(obj => obj.id);
                            let eventTickets = response.data.data.event_tickets;

                            const keysArray = eventTickets
                                .filter(ticket => ticket.ticket_status === 1)
                                .map(ticket => ticket.id);
                            // console.log(keysArray);
                            setTicketIds(keysArray);
                            setCouponApiCall(true);
                        }

                        setLoaderFlag(false)
                    }else{
                        toast.error("Something went wrong");

                        const timer = setTimeout(() => {
                            navigate('/');
                          }, 6000); // Adjust the delay as needed (in milliseconds)
                        return () => clearTimeout(timer);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall, EventId])
   
    //console.log(AllTickets);
    // GET COUPONS API
    useEffect(() => {
        if ((EventId !== 0 || BookTicketEventId !== 0 ) && TicketIds !== "" && CouponApiCall) {
            axios.post("get_coupons", {
                "event_id": EventId ? EventId : BookTicketEventId,
                "ticket_ids": TicketIds
            })
                .then((response) => {
                    // console.log(response.data.data.Coupons);
                    setCoupons(response.data.data.Coupons);
                    setCouponApiCall(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [CouponApiCall, Coupons, EventId, TicketIds, BookTicketEventId]);

    // REMOVE COUPON AMOUNT FROM CART
    const HandleRemoveCouponAmount = (coupon) => {
        AllTickets.forEach(element => {
            // Subtract appliedCouponAmount if set
            if (element["appliedCouponAmount"] && element["appliedCouponId"] === coupon.id) {
                // Remove appliedCouponAmount and appliedCouponCode from the element
                delete element["appliedCouponAmount"];
                delete element["appliedCouponCode"];
                delete element["appliedCouponId"];
            } else {

            }
        });
        setCalTicketPrice(true);
    }

    // ADD COUPONS IN CART
    const handleApplyCoupon = (coupon) => {
        const email = localStorage.getItem("EMAIL");
        // console.log(coupon)
        if (coupon.user_email_address) {
            const couponEmails = coupon.user_email_address.split(',').map(e => e.trim());
            if (!couponEmails.includes(email)) {
                toast.error("Coupon is not applicable for this user.");
                return;
            }
        }
        
        //--------- previous coupon data remove
        let tot_appliedAmount = 0;
        if (appliedCoupons.length > 0) {
            handleRemoveCoupon(appliedCoupons[0]);
        }

        // -------- if check coupon exstist or not from ticket
        const ticketIds = coupon.ticket_details.split(',');
        const isTicketInCart = ticketIds.some(ticketId => CartTicketIds.includes(parseInt(ticketId)));
        if (!isTicketInCart) {
            toast.error("This coupon is not applicable for the selected tickets.");
            return;
        }
        let count = coupon.coupon_count;
        //console.log(ticket)
        CartTicketIds.forEach(ticketId => {
            let appliedAmount = 0;
            const ticket = AllTickets.find(ticket => ticket.id === parseInt(ticketId));
            setNewCouponData(coupon);
        //    console.log(coupon);
            if (ticket) {
                const ticketIdInt = parseInt(ticket.id);
                const ticketStatusInt = parseInt(ticket.ticket_status);
                if (ticketStatusInt === 1) {
                    if (!isNaN(ticketIdInt) && ticketIds.includes(ticketIdInt.toString())) {
                        // console.log(TicketInCart,ticketId);
                        // coupon.coupon_count - backend side count
                        if (coupon.coupon_count < coupon.no_of_discount && count < coupon.no_of_discount) {
                            count += 1; // add item count (frontend)
                            //PERCENTAGE
                            const ticket_price = ticket.show_early_bird === 1 ? ticket.discount_ticket_price : ticket.ticket_price;
                            if (ticket_price && !isNaN(ticket_price) && ticket_price !== 0) {
                                if (coupon.discount_amt_per_type === 1) {
                                    if (coupon.discount_type === 1) { //One time use
                                        appliedAmount += ((ticket_price * coupon.discount_percentage) / 100) * ticket.count;
                                        ticket.appliedCouponAmount = appliedAmount;
                                        ticket.appliedCouponCode = coupon.discount_code;
                                        ticket.appliedCouponId = coupon.id;

                                    } if (coupon.discount_type === 2) { //Multiple time use	
                                        appliedAmount += ((ticket_price * coupon.discount_percentage) / 100) * ticket.count;
                                        ticket.appliedCouponAmount = appliedAmount;
                                        ticket.appliedCouponCode = coupon.discount_code;
                                        ticket.appliedCouponId = coupon.id;
                                        // console.log(appliedAmount, ticket.count);
                                    }
                                }
                            }
                            //AMOUNT

                            // console.log(ticket_price);
                            if (coupon.discount_amt_per_type === 2) {

                                appliedAmount += parseFloat(coupon.discount_amount);
                                // console.log(appliedAmount);
                                ticket.appliedCouponAmount = appliedAmount;
                                ticket.appliedCouponCode = coupon.discount_code;
                                ticket.appliedCouponId = coupon.id;
                            }
                            tot_appliedAmount += appliedAmount;
                        }

                        // console.log(tot_appliedAmount,ticket.count);
                        // Set the new coupon with the calculated applied amount
                        coupon.applied_amount = tot_appliedAmount; // * ticket.count
                        // console.log(coupon);
                        setAppliedCoupons([coupon]);
                        setCalculate(true);
                        setCalTicketPrice(true);
                    }
                } else {
                    // if(ticketStatusInt === 2)
                    // toast.success("This ticket is free, so coupons cannot be applied.")
                }
            }
        });


    };

    const ApplyCouponCode = () => {
        if (CouponCode !== "") {
            // get all coupons public and non-public
            axios.post("get_coupons", {
                "event_id": EventId ? EventId : BookTicketEventId,
                "ticket_ids": TicketIds,
                "show_public": 1
            })
                .then((response) => {
                    if (response.data.data.Coupons.length > 0) {
                        const coupon = response.data.data.Coupons.find(coupon => coupon.discount_code === CouponCode);
                        //console.log(coupon)
                        if (coupon) {
                            handleApplyCoupon(coupon);
                        } else {
                            toast.error("Invalid Coupon Code");
                        }
                    }else{
                        toast.error("Invalid Coupon Code");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    // console.log(Coupons, AllCoupons)

    // CALCULATE FINALAMOUNT = TOTALPRICE - COUPON PRICE(ADD COUPON)
    useEffect(() => {
        if (Calculate) {
            // console.log('asas');
            const final_amt = isNaN(parseFloat(FinalTotalPrice)) ? 0.00 : parseFloat(FinalTotalPrice);
        //    console.log(final_amt,appliedCoupons);
            const totalAmount = final_amt - appliedCoupons.reduce((acc, item) => {
               
                const itemValue = isNaN(parseFloat(item.applied_amount)) ? 0.00 : parseFloat(item.applied_amount);

                //  console.log(item.applied_amount,itemValue,acc);

                return acc + itemValue;
            }, 0);
        //    console.log(totalAmount);
            setFinalTotalPrice(totalAmount.toFixed(2));
            setCalculate(false)
        }
    }, [Calculate, FinalTotalPrice, appliedCoupons, setCalculate]);
// console.log(FinalTotalPrice);
    // REMOVE COUPON FROM CART
    const handleRemoveCoupon = (coupon) => {
        HandleRemoveCouponAmount(coupon)
        setAppliedCoupons(prevCoupons => prevCoupons.filter(item => item.id !== coupon.id));
    };

    const isCouponApplied = (coupon_id) => {
        return appliedCoupons.some(item => item.id === coupon_id);
    };
   //console.log(appliedCoupons);
    // console.log(OrgGstPercentage, TicketYtcrBasePrice, YtcrFeePercentage, PaymentGatewayFeePercentage, PaymentGatewayGstPercentage, CollectGst, PriceTaxesStatus);
   
    // console.log(OverallLimit,BookingLimitExceed);

    // console.log(AllTickets);
    const TicketCount = (index, type) => {
      
        const TicketArr = [...AllTickets];
    
        if (type === 'add') { //------------------------------------ Add items
        //    console.log('ss');
            //---------------- event registration for (single and multiple) 
            if(TicketArr[index]["single_ticket_flag"] && TicketArr[index]["single_ticket_flag"] === 1){
               
                for ( let i = 0; i < TicketArr.length; i++) {
                    // TicketArr[i].single_ticket_flag = 0; // Add or update the key-value pair
                   
                    //------- overall limit and total registration count
                    if(OverallLimit !== 0){
                         
                        if(TicketArr[index]["single_ticket_flag"] === 1 && TicketArr[index]["limit_exceed_flag"] === 0){
                        //   console.log(BookingLimitExceed,OverallLimit);
                          //-- new if cond added for ticket wise limit exceed
                          if(parseInt(TicketArr[index]["total_quantity"]) > parseInt(TicketArr[index]["TotalBookedTickets"])){
                           
                            if(TicketArr[index] !== index){
                    
                                TicketArr[i]["count"] -= 1;
                                TicketArr[i]["Error"] = "";
                                if (TicketArr[i]["count"] < TicketArr[i]["min_booking"]) {
                                   
                                    TicketArr[i]["count"] = 0;
        
                                    // remove ID from CartTicketIds
                                    const ticketIdIndex = CartTicketIds.indexOf(TicketArr[i]["id"]);
                                    if (ticketIdIndex > -1) {
                                        CartTicketIds.splice(ticketIdIndex, 1);
                                    }
                                   
                                    //----------------------
                                    TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                                    Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                        
                                    setCalTicketPrice(true);
                                    // add ID from CartTicketIds
                                    if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                        CartTicketIds.push(TicketArr[index]["id"]);
                                    }
        
                                }
                            }
                          }else{
                            TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                          }
                           
                        }else if(TicketArr[index]["limit_exceed_flag"] === 0 && (parseInt(BookingLimitExceed) === 0 || parseInt(BookingLimitExceed) < parseInt(OverallLimit))){
                            // console.log('2');
                            if(TicketArr[index] !== index){
                    
                                TicketArr[i]["count"] -= 1;
                                TicketArr[i]["Error"] = "";
                                if (TicketArr[i]["count"] < TicketArr[i]["min_booking"]) {
                                
                                    TicketArr[i]["count"] = 0;

                                    // remove ID from CartTicketIds
                                    const ticketIdIndex = CartTicketIds.indexOf(TicketArr[i]["id"]);
                                    if (ticketIdIndex > -1) {
                                        CartTicketIds.splice(ticketIdIndex, 1);
                                    }
                                
                                    //----------------------
                                    TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                                    Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                        
                                    setCalTicketPrice(true);
                                    // add ID from CartTicketIds
                                    if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                        CartTicketIds.push(TicketArr[index]["id"]);
                                    }
                                    TicketArr[i]["Error"] = '';
                                }
                    
                                //--------- temp count store
                                TempCount += TicketArr[index]["count"];
                                setTempCount(TempCount);
                            }
                        }else{
                            TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                        }

                    }else{
                     
                        //------- overall limit and total registration count
                        if(OverallLimit !== 0){
                            
                            if(TicketArr[index]["limit_exceed_flag"] === 0 && (parseInt(BookingLimitExceed) === 0 || parseInt(BookingLimitExceed) < parseInt(OverallLimit))){
                                TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                                
                                //--------- temp count store
                                TempCount += TicketArr[index]["count"];
                                setTempCount(TempCount);
                            
                                Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                                setCalTicketPrice(true);
                                // add ID from CartTicketIds
                                if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                    CartTicketIds.push(TicketArr[index]["id"]);
                                }
                                TicketArr[index]["Error"] = '';
                              
                            }else{
                                TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                            }
                     
                        }else{
                           
                            if(TicketArr[index]["single_ticket_flag"] && TicketArr[index]["single_ticket_flag"] === 1){
                                if(TicketArr[i] !== index){
                                
                                    TicketArr[i]["count"] = 0;
                                    // remove ID from CartTicketIds
                                    const ticketIdIndex = CartTicketIds.indexOf(TicketArr[i]["id"]);
                                    if (ticketIdIndex > -1) {
                                        CartTicketIds.splice(ticketIdIndex, 1);
                                    }
                                    TicketArr[i]["Error"] = '';
                                }

                                //---------- new added for registration limit exceed
                                if(TicketArr[index]["total_quantity"] > TicketArr[index]["TotalBookedTickets"] && (TicketArr[index]["total_quantity"] >= parseFloat(TicketArr[index]["TotalBookedTickets"]+TicketArr[index]["min_booking"]) )){ 

                                    TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                                    Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                                    setCalTicketPrice(true);
                                    // add ID from CartTicketIds
                                    if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                        CartTicketIds.push(TicketArr[index]["id"]);
                                    }
                                    TicketArr[index]["TotalBookedTickets"] += TicketArr[index]["min_booking"];
                                }else{
                                    TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                                }
                                // TicketArr[index]["Error"] = 'Single category allowed';
                            }else{
                               
                                if(TicketArr[index] !== index){
                    
                                    TicketArr[i]["count"] -= 1;
                                    TicketArr[i]["Error"] = "";
                                    if (TicketArr[i]["count"] < TicketArr[i]["min_booking"]) {
                                       
                                        TicketArr[i]["count"] = 0;
            
                                        // remove ID from CartTicketIds
                                        const ticketIdIndex = CartTicketIds.indexOf(TicketArr[i]["id"]);
                                        if (ticketIdIndex > -1) {
                                            CartTicketIds.splice(ticketIdIndex, 1);
                                        }
                                       
                                        //----------------------
                                        TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                                        Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                            
                                        setCalTicketPrice(true);
                                        // add ID from CartTicketIds
                                        if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                            CartTicketIds.push(TicketArr[index]["id"]);
                                        }
            
                                    }
                                }
                            }
                       
                        } //-------- else end
                        
                    }
    
                }

            }else{
               
               //----------
                if(TicketArr[index]["limit_exceed_flag"] === 1){
                    TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                }else{
                    // console.log(BookingLimitExceed, OverallLimit)
                    if(OverallLimit !== 0 && (parseInt(BookingLimitExceed) > parseInt(OverallLimit))){
                        TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                    }else{
                      
                        //---------- new added for registration limit exceed
                        if(TicketArr[index]["total_quantity"] > TicketArr[index]["TotalBookedTickets"] && (TicketArr[index]["total_quantity"] >= parseFloat(TicketArr[index]["TotalBookedTickets"]+TicketArr[index]["min_booking"]) )){
                           
                            TicketArr[index]["count"] = TicketArr[index]["min_booking"];
                            Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                            setCalTicketPrice(true);

                            // add ID from CartTicketIds
                            if (!CartTicketIds.includes(TicketArr[index]["id"])) {
                                CartTicketIds.push(TicketArr[index]["id"]);
                            }

                            TicketArr[index]["TotalBookedTickets"] += TicketArr[index]["min_booking"];
                            
                        }else{
                            TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                        }
                    }
                }

                TempCount += TicketArr[index]["count"];
                setTempCount(TempCount);
            }
          
            //----------- new added
            if(EventRegiStatus === 1){
                for (let item of TicketArr) {
                    item.single_ticket_flag = 1; // Add or update the key-value pair
                }
            }

        }
        else if (type === 'plus') { //------------------------------------ (+) items
            // if(EventRegiStatus === 1){
            //     TicketArr[index]["Error"] = 'Single category allowed';
            // }else{
               
                if (TicketArr[index]["count"] < TicketArr[index]["max_booking"]) {
                    console.log('aa');
                    if(OverallLimit === 0){
                        
                        //---------- new added for registration limit exceed
                        if(TicketArr[index]["total_quantity"] > TicketArr[index]["TotalBookedTickets"]){
                            TicketArr[index]["count"] += 1;
                            TicketArr[index]["Error"] = "";
            
                            Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                            setCalTicketPrice(true);  
                            TicketArr[index]["TotalBookedTickets"] += 1;   
                        }else{
                            TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                        }

                    }else if(TicketArr[index]["limit_exceed_flag"] === 0 && (parseInt(BookingLimitExceed) === 0 || parseInt(BookingLimitExceed) < parseInt(OverallLimit))){
                      //---------- new added for registration limit exceed
                        if(TicketArr[index]["total_quantity"] > TicketArr[index]["TotalBookedTickets"]){
                            
                            TicketArr[index]["count"] += 1;
                            TicketArr[index]["Error"] = "";
            
                            Calculation(TicketArr[index], CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
                            setCalTicketPrice(true);    
                        
                            //--------- temp count store
                            TempCount += (TicketArr[index]["count"]); //- TempCount
                            setTempCount(TempCount);

                            TicketArr[index]["TotalBookedTickets"] += 1; 
                           
                        }else{
                            TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                        }

                    }else{
                        TicketArr[index]["Error"] = 'Maximum booking Limit Exceed.';
                    }
                   
                }
                else {
                    TicketArr[index]["Error"] = "Maximum " + TicketArr[index]["max_booking"] + " category allowed";
                }
           // }
            
        } else {  //------------------------------------ remove items
        
            if (TicketArr[index]["count"] !== 0) {
              
                
                TicketArr[index]["count"] -= 1;
                TicketArr[index]["Error"] = "";
                if (TicketArr[index]["count"] < TicketArr[index]["min_booking"]) {
                    TicketArr[index]["count"] = 0;
                   
                    // remove ID from CartTicketIds
                    const ticketIdIndex = CartTicketIds.indexOf(TicketArr[index]["id"]);
                    if (ticketIdIndex > -1) {
                        CartTicketIds.splice(ticketIdIndex, 1);
                    }
                    // console.log('sss');
                    //--------- temp count store
                    TempCount -= TicketArr[index]["min_booking"];
                    setTempCount(TempCount);
                    TicketArr[index]["Error"] = '';
                    
                    //---------- new added for registration limit exceed
                    TicketArr[index]["TotalBookedTickets"] -= TicketArr[index]["min_booking"];
                
                }else{
                   
                    //---------- new added for registration limit exceed
                    TicketArr[index]["TotalBookedTickets"] -= 1;

                    //--------- temp count store
                    TempCount -= TicketArr[index]["count"];
                    setTempCount(TempCount);
                    TicketArr[index]["Error"] = '';
                }
        
                for ( let i = 0; i < TicketArr.length; i++) {
                    TicketArr[i]["Error"] = "";
                }
                
                //----------- new added
                for (let item of TicketArr) {
                    item.single_ticket_flag = 0; // Add or update the key-value pair
                }
            }

        }

        // console.log(TicketArr);
        setCounterCalculate(true);
        setAllTickets(TicketArr);

    }

    useEffect(() => {
    //    console.log(TempCount);
        const BookingTotal = (parseInt(TotalBookingRegistration) + parseInt(TempCount));
        setBookingLimitExceed(BookingTotal);
    }, [TempCount]);
    
    // console.log(BookingLimitExceed)

    // CALCULATE TICKET PRICE WITH EARLY BIRD
    //--------- add button click on all and multiple ticket wise amount adding (early bird discount included)
    useEffect(() => {
        if (AllTickets.length > 0 && CounterCalculate) {
            // console.log("first")
            let total_discount = 0;
            let total = 0;
            let total_attendee = 0;
            let total_amt = 0;

            const currentTimeStamp = Math.floor(Date.now() / 1000); //In seconds hence / 1000
            // console.log(AllTickets);
            AllTickets.forEach(element => {
               // console.log(element['count'],element["ticket_price"])
                let discount = 0;
                if (element['count'] !== 0) {

                    if (element["ticket_status"] === 1) {
                        total = (element['count'] * element["ticket_price"]);
                        total_amt += total;
                        // console.log(total,element['count'],element["ticket_price"])
                        // console.log(element["id"],total)
                        // CALCULATE DISCOUNT ON TOTAL PRICE
                        if (element['early_bird'] === 1) {
                            if (element['TotalBookedTickets'] <= element['no_of_tickets']) {
                                if (element['start_time'] !== "" && element['end_time'] !== "") {
                                    if (currentTimeStamp >= element['start_time'] && element['end_time'] >= currentTimeStamp) {
                                        //console.log(total,element['discount_value']);
                                        // console.log(currentTimeStamp,element['start_time'],element['end_time']);
                                        if (element['discount'] === 1) { //percentage
                                            discount = total * (element['discount_value'] / 100);
                                        } else if (element['discount'] === 2) { //amount
                                            discount = element['count'] * element['discount_value'];
                                        }
                                    }
                                } else {
                                    if (element['discount'] === 1) { //percentage
                                        discount = total * (element['discount_value'] / 100);
                                    } else if (element['discount'] === 2) { //amount
                                        discount = element['count'] * element['discount_value'];
                                    }
                                }
                            }
                            total_amt -= discount; // count * discount (2*165 = 330)
                           // console.log(discount);
                        }
                        total_discount += discount;
                    }
                    total_attendee += element['count'];

                }
                element["ticket_discount"] = discount / element["count"];
                // }
            })
            // console.log(total_amt)
           
            setTotalPrice(total_amt);
            setFinalTotalPrice(total_amt)
            setTotalAttendee(total_attendee);
            // console.log(total_discount);
            setTotalDiscount(total_discount);
            setCounterCalculate(false)
            handleRemovePreviousAppliedCoupon(total_amt);
        }
    }, [AllTickets, CounterCalculate])
//console.log(FinalTotalPrice);
    // CALCULATE FINAL TICKETS PRICES AFTER CALCULATE ALL GST 
//    console.log(AllTickets);
    // useEffect(() => {
    //     if (CalTicketPrice) {
    //         let total = 0;
    //         let coupon_total = 0;
    //         let totalAmount = 0;
    //         AllTickets.forEach(element => {
    //             if (element['count'] !== 0 && element['ticket_status'] === 1) {
    //                 // let ticket_price = element['show_early_bird'] === 1 ? element['discount_ticket_price'] : element['ticket_price']; TotalPrice
    //                 let ticket_price = element['show_early_bird'] === 1 ? element['discount_ticket_price'] : element['ticket_price']; 
    //                // console.log(element['discount_ticket_price']);
    //                 total += (element['count'] * ticket_price);
    //                 // console.log(total,element["appliedCouponAmount"]);

    //                 // Subtract appliedCouponAmount if set
    //                 // if (element["appliedCouponAmount"]) {
    //                 //    //coupon_total += element["appliedCouponAmount"];
    //                 //     total -= element["appliedCouponAmount"];
    //                 // }

                    
    //                //console.log(totalAmount,total);
    //                 // if (element["appliedCouponAmount"]) {
    //                 //     total -= element["appliedCouponAmount"];
    //                 // }
                  
    //               //  console.log(totalAmount,total);
                  
    //                 // console.log("here to calculate");
    //                 Calculation(element, CollectGst, PriceTaxesStatus,'', RaceCategoryCharges);
    //                 // console.log(result);
    //                 setCalTicketPrice(true);
    //             }
    //         })
    //     //   console.log(total);
    //         for (let item of appliedCoupons) {
    //             if(item.discount_amt_per_type === 1){
    //                 totalAmount = (total * item.discount_percentage)/100;
    //             }else{
    //                 totalAmount = item.discount_amount;
    //                 // console.log(total,item.discount_amount,totalAmount);
    //             }
    //         }
    //        total -= totalAmount;
    //         // console.log(coupon_total);
    //         // setTotalPrice(total);
    //         setFinalTotalPrice(total);
    //         setCalTicketPrice(false);
    //     }
    // }, [AllTickets, CalTicketPrice, CollectGst, OrgGstPercentage, PaymentGatewayFeePercentage, PaymentGatewayGstPercentage, PriceTaxesStatus, TicketYtcrBasePrice, YtcrFeePercentage])

    useEffect(() => {
        if (CalTicketPrice) {
            let total = 0;
            let coupon_total = 0;
            let totalAmount = 0;
            let totalAmount1 = 0
            let single_ticket_price = 0;
            AllTickets.forEach(element => {
                if (element['count'] !== 0 && element['ticket_status'] === 1) {
                    // let ticket_price = element['show_early_bird'] === 1 ? element['discount_ticket_price'] : element['ticket_price']; TotalPrice
                    let ticket_price = element['show_early_bird'] === 1 ? element['discount_ticket_price'] : element['ticket_price']; 
                //    console.log(element['count'] , ticket_price);
                    single_ticket_price = (element['count'] * ticket_price);
                    // console.log(element["appliedCouponAmount"]);
                    // console.log(total,element["appliedCouponAmount"]);

                    // Subtract appliedCouponAmount if set
                    // if (element["appliedCouponAmount"]) {
                    //    //coupon_total += element["appliedCouponAmount"];
                    //     total -= element["appliedCouponAmount"];
                    // }

                    
                   //console.log(totalAmount,total);
                    // if (element["appliedCouponAmount"]) {
                    //     total -= element["appliedCouponAmount"];
                    // }
                  
                //    console.log(appliedCoupons);
                    //-------------------
                    if(Coupons){
                        for (let item of appliedCoupons) {

                            const CouponTicketIds = item.ticket_details.split(',');
                            const IsCouponApplied = CouponTicketIds.includes(element["id"].toString());
                        // console.log(IsCouponApplied);
                            if(IsCouponApplied && (element["id"] === ClickOnTicketId)){
                                if(item.discount_amt_per_type === 1){
                                    totalAmount = (single_ticket_price * item.discount_percentage)/100;
                                }else{
                                    totalAmount = item.discount_amount;
                                }
                                totalAmount1 += (totalAmount);
                            //    console.log(element['count'] , totalAmount, totalAmount1);
                            }else{
                              
                               totalAmount1 += item.applied_amount ? item.applied_amount : 0;
                            }

                        }
                    }
                    //  console.log(totalAmount);
                //    element.appliedCouponAmount += totalAmount1;

                    total += (element['count'] * ticket_price);
                    // console.log(total);
                    // console.log("here to calculate");
                    Calculation(element, CollectGst, PriceTaxesStatus,'', RaceCategoryCharges, 0, appliedCoupons);
                    // console.log(result);
                    setCalTicketPrice(true);
                }  
            })
           
       
           total -= totalAmount1;
        //    console.log(total);
            // console.log(coupon_total);
            // setTotalPrice(total);
            setFinalTotalPrice(total);
            setCalTicketPrice(false);
        }
    }, [AllTickets, CalTicketPrice, CollectGst, OrgGstPercentage, PaymentGatewayFeePercentage, PaymentGatewayGstPercentage, PriceTaxesStatus, TicketYtcrBasePrice, YtcrFeePercentage])

    const HandleContinue = (e) => {
        e.preventDefault();
        navigate('/ticket_registration/' + EventId, {
            state: {
                TotalAttendee: TotalAttendee,
                AllTickets: AllTickets,
                TotalPrice: TotalPrice,
                TotalDiscount: TotalDiscount,
                EventData: EventData,
                UtmCampaign: UtmCampaign,
                FinalTotalPrice: FinalTotalPrice
            }
        });
    }

    const BackToHome = (flag) => {
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    const BackToEventDetails = (e) => {
        navigate('/event_details', { state: { event_id: EventId } })
    }
   // console.log(TotalPrice);

    //-----------------------
    const handleRemovePreviousAppliedCoupon = (TotalPrice) => {
        // console.log(appliedCoupons);
        const final_amt = isNaN(parseFloat(TotalPrice)) ? 0.00 : parseFloat(TotalPrice);
        // console.log(final_amt,appliedCoupons);
        let totalAmount = 0;
        for (let item of appliedCoupons) {
            if(item.discount_amt_per_type === 1){
                totalAmount = (final_amt * item.discount_percentage)/100;
            }else{
                totalAmount = (final_amt-item.discount_percentage);
            }
        }
        
        NewCouponData.applied_amount = totalAmount; // * ticket.count
        // console.log(NewCouponData,totalAmount);
        // setAppliedCoupons([NewCouponData]);
        const newFinalTot = (final_amt - totalAmount);
        setFinalTotalPrice(newFinalTot.toFixed(2));
    }
    //console.log(FinalTotalPrice);

    return (
        <>

            {PaymentFailurePopup === true ?

                <div className="modal is-visible" data-animation="slideInUp">
                    <div className="modal-dialog quickview__main--wrapper">
                        <div className="quickview__inner">
                            <div className="row">
                                <div className="login__section">
                                    <div className="login__section--inner">
                                        <div className="p-5">
                                            <div className="account__login--header mb-25 text-center">

                                                <img src={failedImg} alt="" style={{ height: 150 }} />
                                                <p className='h3 my-3'>Payment failed</p>
                                                <p className="account__login--header__desc mb-0">Unfortunately sonthing went wrong.</p>
                                                <p className="account__login--header__desc mb-2">Please try again...</p>

                                            </div>
                                            <div className="d-flex gap-4">
                                                <button className="account__login--btn secondary__btn mt-2" onClick={(e) => { setPaymentFailurePopup(false); navigate('/register_now/' + EventId); }} >Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :
                ''
            }

            {LoaderFlag ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <HeaderSecond Login={Login} setLogin={setLogin} />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        SECURE CHECKOUT
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">

                                        <li className="breadcrumb__content--menu__items" onClick={(e) => BackToHome('home')}>
                                            <div className="text-white">
                                                Home
                                            </div>
                                        </li>

                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Register Now</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                                <div className="main checkout__mian">

                                    <div className="checkout__content--step section__shipping--address ">
                                        <div className="mb-5">
                                            <div className="section__header checkout__section--header d-flex align-items-center mb-4">

                                                <div className="row w-100">
                                                    <div className="col-sm-10">
                                                        <h2 className="section__header--title h3 mb-3">{EventData ? EventData.display_name : ''}</h2>
                                                        {AllTickets && AllTickets.length > 0 ?
                                                            <p>{EventData.start_date} | {EventData.city_name}</p>
                                                            : null}
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="event-visibility hand_cursor" style={{ width: "fit-content", float: "right" }} onClick={(e) => BackToEventDetails(e)}>
                                                            <div className="visibility gap-1 ">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height={20}
                                                                    viewBox="0 -960 960 960"
                                                                    width={20}
                                                                >
                                                                    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
                                                                </svg>
                                                                Back
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                    </div>
       {/* {console.log(AllTickets)} Single category allowed */}
                                    { EventRegiStatus === 1 ?
                                    <div className="select-event mb-2">
                                       <div className="row">
                                           <div className="col-sm-12">
                                              <p className="text-right text-danger">Only single category selection is allowed.</p>
                                           </div>
                                       </div>
                                    </div>
                                    : '' }

                                    <div className="select-event mb-5">
                                        <div className="row">
                                            {AllTickets && AllTickets.length > 0 ? (
                                                AllTickets.map((item, index) => (

                                                    <div className="col-sm-12 safe-checkout mb-4" key={index}>
                                                        <div className="card"
                                                                style={{
                                                                    backgroundColor:
                                                                        (item.event_id === 56 || item.event_id === 74) &&
                                                                            (item.id === 174 || item.id === 184 || item.id === 176 || item.id === 186)
                                                                            ? "#bcd6ff"
                                                                            : "",
                                                                }}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10">
                                                                    <h4 className="header-text text-left">{item.display_ticket_name}</h4>
                                                                    {/* <span className="tooltiptext position-fixed border-radius-10 p-4 w-auto">{item.ticket_name}</span> */}
                                                                    {item.ticket_description !== "" ?
                                                                        <p className="header-text text-left" style={{whiteSpace: 'pre-line'}}>{item.ticket_description}</p>
                                                                        : null}
                                                                    {
                                                                        item.ticket_status === 1 ?
                                                                            item.show_early_bird === 1 ?
                                                                                <div className="text-left">
                                                                                    <div className="ticket-prices">
                                                                                        <div className="original-price">
                                                                                            ₹{item.ticket_price}
                                                                                        </div>
                                                                                        <div className="discount-price">
                                                                                            ₹{item.discount_ticket_price}
                                                                                        </div>
                                                                                        <div className="total-discount">
                                                                                            ₹{item.total_discount}{" "}OFF
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="text-left"> ₹{item.ticket_price}</div>
                                                                            :
                                                                            <div className="text-left"><span className="total-discount">Free</span></div>
                                                                    }

                                                                        <div className='text-left'>
                                                                        {item.ticket_sale_end_date !== "" ?
                                                                        <small className="" > <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" ><path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" /></svg> {"Closing on : " + item.ticket_sale_end_date}</small>
                                                                        : null}
                                                                        </div>

                                                                        
                                                                    <p className="text-right text-danger">{item.Error ? item.Error : ""}</p>
                                                                </div>
                                                                {item.count < item.min_booking ?
                                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 custom-ticket-btns cursor-pointer" >
                                                                        <p className="blog__content--btn primary__btn m-0" onClick={() => TicketCount(index, 'add')}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>Add</p>
                                                                        {item.RemainingTickets !== 0 ?
                                                                        <p className="blog__content--btn primary__btn  badge-light m-0" >{item.RemainingTickets + " Left"}</p>
                                                                        : null}
                                                                    </div>
                                                                    :
                                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 custom-ticket-btns">
                                                                        <div className="d-flex align-items-center blog__content--btn primary__btn m-0 plus-minus-btn">
                                                                            <p className=" m-0 minus" onClick={() => TicketCount(index, 'minus')}>-</p>
                                                                            <input className="count" type="text" value={item.count} readOnly />
                                                                            <p className=" m-0 plus" onClick={() => TicketCount(index, 'plus')}>+</p>
                                                                        </div>
                                                                        {item.RemainingTickets !== 0 ?
                                                                        <p className="blog__content--btn primary__btn  badge-light m-0" >{item.RemainingTickets + " Left"}</p>
                                                                        : null}
                                                                    </div>
                                                                }

                                                                        
                                                                

                                                            </div>

                                                            <div className='row d-flex align-items-center'>
                                                                <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10 text-left">
                                                                   
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 text-center">
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No registration available</p>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 safe-checkout-summary">
                                {parseInt(TotalAttendee) !== 0 ?
                                    <>
                                        <aside className="checkout__sidebar sidebar border-radius-10 position-relative top-0">
                                            <h2 className="section__header--title text-center h3 mb-5">SUMMARY</h2>
                                            <div className="card p-4 mb-4">
                                                <div className="row my-3">
                                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">

                                                        <p className="m-0">Price ({TotalAttendee} Registration)</p>
                                                    </div>

                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                                        {/* <p className="m-0">₹{parseFloat(TotalPrice).toFixed(2)}</p> */}
                                                        <p className="m-0">₹{parseFloat(TotalPrice).toFixed(2)}</p>
                                                    </div>

                                                    {/* APPLIED COUPONS */}
                                                    {/* {console.log(appliedCoupons)} */}
                                                    <div>
                                                        {appliedCoupons.map((item, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="row">
                                                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                                            <p className="m-0">{`${item.discount_code}`} (Coupon)</p>
                                                                        </div>
                                                                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                                                                            <p className="m-0">₹{(item.applied_amount === "" || isNaN(item.applied_amount)) ? "0.00" : parseFloat(item.applied_amount).toFixed(2)}</p>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                    {/*  */}
                                                </div>

                                                <div className="row my-3">
                                                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                        <p className="h4 m-0">Total Amount</p> (<small>{EventData.prices_taxes_status}</small>)
                                                        {/* <small>Prices are excluding Booking Fees</small> */}
                                                    </div>
                                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-end">
                                                        <p className="h4 m-0">₹{parseFloat(FinalTotalPrice).toFixed(2)}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='card p-3 py-1 summary-proceed mobile-btn-ui' onClick={(e) => HandleContinue(e)}>
                                                <div className="row my-3">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 col-xxl-6">
                                                        <p className="h4 m-0"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113Z" /></svg> {TotalAttendee}</p>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-6 col-xxl-6 d-flex align-items-center justify-content-end">
                                                        <p className="h4 m-0">PROCEED <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>

                                        <aside>
                                            <div className="checkout__sidebar sidebar border-radius-10 mt-4 position-relative top-0">
                                                <div className="form-floating mt-3 mb-0 d-flex align-items-center">
                                                    <input type="text" className="form-control" placeholder="" maxLength={100} value={CouponCode} onChange={(e) => setCouponCode(e.target.value)} />
                                                    <label>Enter coupon code</label>
                                                    {CouponCode ?
                                                        <div className="cursor-pointer" style={{ position: "absolute", right: "20px" }} onClick={(e) => ApplyCouponCode(e)}>Apply</div>
                                                        : null}
                                                </div>
                                                {Coupons.length > 0 ?
                                                    <>
                                                        {Coupons.map((item, index) => (
                                                            <div className="my-4 coupon-code-ui">
                                                                {/* <div className="card border-radius-10 mb-4">
                                                                    <div className="row p-4">
                                                                        <div className="col-3 col-sm-3 col-md-3 col-lg-12 col-xl-12 col-xxl-3 d-flex align-items-center justify-content-center">
                                                                            <img src={couponDiscount} alt="" style={{ width: "60px" }} />
                                                                        </div>
                                                                        <div className="col-9 col-sm-9 col-md-9 col-lg-12 col-xl-12 col-xxl-6">
                                                                            <h4 className="">{item.discount_code}</h4>
                                                                           
                                                                            <small className="px-2 py-1 border-radius-5 dis-coupon-lable">
                                                                                {item.discount_amt_per_type === 1
                                                                                    ? `Save ${item.discount_percentage}% on this event`
                                                                                    : `Save ₹${item.discount_amount} on this event`}
                                                                            </small>
                                                                            <hr className="dotted-line" />
                                                                            <small className="px-2 py-1 border-radius-5 close-popup-icon">{item.description}</small>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-3 d-flex justify-content-center hand_cursor">
                                                                            {isCouponApplied(item.id) ? (
                                                                                <p className="apply-btn" onClick={() => handleRemoveCoupon(item)}>Remove</p>
                                                                            ) : (
                                                                                <p className="apply-btn" onClick={() => handleApplyCoupon(item)}>Apply</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="card border-radius-10 mb-4">
                                                                    <div className="row p-4">
                                                                        <div className="col-3 col-sm-3 col-md-3 col-lg-12 col-xl-12 col-xxl-3 d-flex align-items-center justify-content-center">
                                                                            <img src={couponDiscount} alt="" style={{ width: "60px" }} />
                                                                        </div>
                                                                        <div className="col-9 col-sm-9 col-md-9 col-lg-12 col-xl-12 col-xxl-6">
                                                                            <h4 className="">{item.discount_code}</h4>
                                                                            {/* <small className="px-2 py-1 border-radius-5 dis-coupon-lable">Save ₹120 on this event</small> */}
                                                                            <small className="px-0 py-1 border-radius-5 dis-coupon-lable">
                                                                                {item.discount_amt_per_type === 1
                                                                                    ? `Save ${item.discount_percentage}% on this event`
                                                                                    : `Save ₹${item.discount_amount} on this event`}
                                                                            </small>
                                                                            <hr className="dotted-line" />
                                                                            <small className="px-0 py-1 border-radius-5 close-popup-icon">{item.description}</small>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-3 d-flex justify-content-center align-items-center hand_cursor">
                                                                            {isCouponApplied(item.id) ? (
                                                                                <p className="apply-btn my-2" onClick={() => handleRemoveCoupon(item)}>Remove</p>
                                                                            ) : (
                                                                                <p className="apply-btn my-2" onClick={() => handleApplyCoupon(item)}>Apply</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </>
                                                    : null}
                                            </div>

                                        </aside>

                                    </>
                                    :
                                    <aside className="checkout__sidebar sidebar border-radius-10 text-center">
                                        <img src={NoItemAdded} alt="" style={{ width: 250 }} />
                                        <h4 className="pt-4">No Registration Added</h4>
                                    </aside>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main >

        </>
    )
}

export default RegisterNow